import { isPlainObject } from "lodash";
import { apiUrl, _axios_base_get_list } from "../..";
import { depositApiPrefix } from "../index";

export default async function getDeposits(
  limit,
  skip,
  query = {},
  accessKey = ""
) {
  if (!isPlainObject(query)) {
    query = {};
  }

  return await _axios_base_get_list(
    `${apiUrl}/${depositApiPrefix}/getDeposits`,
    limit,
    skip,
    query,
    accessKey
  );
}
