
// const PAGESIZE = 30;

import { depositApiPrefix } from "..";
import { apiUrl, _base_axios_post } from "../..";

export default function calculateWithdrawalLimit(query = {}) {
  return _base_axios_post(
    `${apiUrl}/${depositApiPrefix}/calculateWithdrawalLimit`,
    query
  );
}
