import { get, isEmpty, isPlainObject } from "lodash";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { triggerModal } from "../../../redux/actions/app-actions";
import SetupGoogle2FADrawer from "../../setting/components/SetupGoogle2FADrawer";

// markup
const RequireAuthWrapper = (props) => {
  const [user, setUser] = useState({});
  const [visible, setVisible] = useState(false);
  const accessKey = get(props.user, "accessKey");

  useEffect(() => {
    if (
      isPlainObject(user) &&
      !isEmpty(user) &&
      !get(user, "isBindGoogleKey")
    ) {
      // setVisible(true);
      props.triggerModal({ type: "paymentPasswordOr2FA" });
    }
  }, [user]);

  return (
    <React.Fragment>
      {props.children}

      <SetupGoogle2FADrawer
        visible={visible}
        onClose={() => {
          setVisible(false);
        }}
      ></SetupGoogle2FADrawer>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = {
  triggerModal,
};
export default connect(mapStateToProps, mapDispatchToProps)(RequireAuthWrapper);
