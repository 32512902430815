import { apiUrl, _axios_base_get_list } from "../..";
import { depositApiPrefix } from "../index";

export default async function getWithdrawTransaction(
  limit,
  skip,
  query = {},
  accessKey = ""
) {
  return await _axios_base_get_list(
    `${apiUrl}/${depositApiPrefix}/getWithdrawTransaction`,
    limit,
    skip,
    query,
    accessKey
  );
}
