import {
  EyeInvisibleOutlined,
  EyeTwoTone,
  FileProtectOutlined,
  ToolFilled,
} from "@ant-design/icons";
import {
  Button,
  Divider,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Select,
  Space,
  Spin,
  Typography,
} from "antd";
import { useForm } from "antd/lib/form/Form";
import { navigate } from "gatsby-link";
import { cloneDeep, get, isEmpty, isNaN, map, sumBy } from "lodash";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { feathers } from "../../../api/feathers_rest";
import { sourceKey } from "../../../locales/config";
import { useTranslation } from "../../../locales/useTranslation";
import getDeposits from "../../../newApi/asset/deposit/getDeposits";
import getWithdrawTransaction from "../../../newApi/asset/withdrawal/getWithdrawTransaction";
import freezeUserWallet from "../../../newApi/pairing/freezeUserWallet";
import getPairingUser from "../../../newApi/pairing/getPairingUser";
import { notificationEmail } from "../../../newApi/user";
import sendRegisterVerificationEmail from "../../../newApi/user/sendRegisterVerificationEmail";
import validatePassword from "../../../newApi/user/validatePassword";
import { routes } from "../../../route";
import { formatNumber } from "../../../utilities/common-function";
import { verifyGoogle2faCode } from "../../../utilities/google-2fa-qr";
import Layout from "../../general/components/Layout";
import NavHeader from "../../general/components/NavHeader";
import Required2FAWrapper from "../components/Required2FAWrapper";
import RequiredPaymentPasswordWrapper from "../components/RequiredPaymentPasswordWrapper";
import createWithdrawalRequest from "../../../newApi/asset/withdrawal/createWithdrawalRequest";
import calculateWithdrawalLimit from "../../../newApi/asset/withdrawal/calculateWithdrawalLimit";
import getProfiles from "../../../newApi/profile/getProfiles";
import { freezeWalletImg } from "../../../images";

// markup
const Withdrawal = (props) => {
  // const location = useLocation();
  const { t } = useTranslation();
  const [form] = useForm();
  const [loading, setLoading] = useState(false);
  // const [showFilterModal, setShowFilterModal] = useState(false);
  // const [selectedFilter, setSelectedFilter] = useState("");
  const [address, setAddress] = useState([]);
  const [withdrawFees, setWithdrawFees] = useState(0);
  const [withdrawalDesc, setWithdrawaltDesc] = useState("");
  const [is2FA, setIs2FA] = useState(false);
  const [confirmModalVisible, setConfirmModalVisible] = useState(false);
  const [confirmData, setConfirmData] = useState({});
  const [pairingUserInfo, setPairingUserInfo] = useState({});
  const [withdrawable, setWithdrawable] = useState(0);
  const [updatedProfile, setUpdatedProfile] = useState({});
  const [balance, setBalance] = useState(0);
  const [disableWithdraw, setDisableWithdraw] = useState(false);
  //const [user, setUser] = useState({});
  const accessKey = get(props.user, "accessKey");
  const user_login = get(props, "user.user");
  const profile = get(user_login, "profile");
  const secretKey = get(user_login, "secretKey");
  let timeoutFunc;

  function handleSubmit() {
    let correctValues = {};
    setLoading(true);

    form
      .validateFields()
      .then((values) => {
        let password = get(values, "password");
        // let email = get(user_login, "email");
        let username = get(user_login, "username");
        let google_code = get(values, "checkCode");
        let secretKey = get(user_login, "secretKey");

        validatePassword(username, password, accessKey)
          .then((res) => {
            let validate_message = get(res, "message");
            if (validate_message === "User password is correct") {
              ///////// 2FA code //////////////
              if (verifyGoogle2faCode(secretKey, google_code) === true) {
                correctValues = {
                  userId: get(profile, "pairingUserId"),
                  amount: parseFloat(values.amount),
                  withdrawalAddress: values.address,
                  amount: parseFloat(values.amount),
                  profileId: get(profile, "_id"),
                  serviceCharge: withdrawFees,
                };

                // freezeUserWallet({
                //   userId: get(profile, "pairingUserId"),
                //   amount: parseFloat(values.amount),
                // })
                //   .then((res) => {
                // console.log("freeRes", res);
                createWithdrawalRequest(correctValues)
                  // feathers("withdraw-transactions", accessKey)
                  //   .create(correctValues)
                  .then((res) => {
                    message.success(t("submitWithdrawal", sourceKey.asset));
                    setLoading(false);
                    setConfirmData({});
                    setConfirmModalVisible(false);
                    navigate(routes.withdrawalList.to());
                    //     setLoading(false);
                    // let mail = {
                    //   to: notificationEmail,
                    //   subject: "Notification for Withdrawal Request",
                    //   text: `${get(user_login, "username")} - ${get(
                    //     user_login,
                    //     "email"
                    //   )}, has made a ${
                    //     values.amount
                    //   } USDT withdrawal request.`,
                    //   type: "notification",
                    // };

                    // sendRegisterVerificationEmail(mail)
                    //   .then((res) => {
                    //     navigate(routes.withdrawalList.to());
                    //     setLoading(false);
                    //   })
                    //   .catch((err) => {
                    //     message.error(err);
                    //     setLoading(false);
                    //   });
                  })
                  .catch((err) => {
                    setLoading(false);
                    message.error(err?.message);
                  });
                // })
                // .catch((err) => {
                //   console.log(err);
                //   setLoading(false);
                //   message.error(err?.message);
                // });
              } else {
                // message.error("Invalid Google 2FA Code");
                message.error(t("invalid2FA", sourceKey.asset));
                setLoading(false);
              }
            } else {
              message.error(t("invalidPassword"));
              setLoading(false);
            }
          })
          .catch((err) => {
            message.error("Something Went Wrong in Login");
            setLoading(false);
          });

        //// old version
        /* values.amount = parseFloat(values.amount);
        withdrawal(values, accessKey)
          .then((res) => {
            if (get(res, "data.status") === "200") {
              message.success("提币成功");
              navigate(routes.asset.to());
            } else {
              message.error(get(res, "data.msg") || get(res, "data.msgCode"));
            }
          })
          .catch((err) => {}); */
      })
      .catch((err) => {
        message.error(t("keyInAllInfo", sourceKey.asset));
        setLoading(false);
      });
  }

  // const { TextArea } = Input;

  if (typeof document != "undefined") {
    var textAreas = document.getElementsByTagName("TextArea");

    Array.prototype.forEach.call(textAreas, function (elem) {
      elem.placeholder = elem.placeholder.replace(/\\n/g, "\n");
    });
  }

  function getServiceCharge() {
    feathers("system-configuration", accessKey)
      .find({ key: "serviceCharge" })
      .then((res) => {
        let result = get(res, "result[0]");

        if (!isEmpty(get(result, "serviceCharge"))) {
          if (get(result, "serviceCharge") !== "-") {
            let serviceCharge = parseFloat(get(result, "serviceCharge"));
            setWithdrawFees(serviceCharge);
          }
        }
      })
      .catch((err) => {});
  }

  function getDesc() {
    feathers("system-configuration", accessKey)
      .find({ key: "withdrawalDescription" })
      .then((res) => {
        let result = get(res, "result[0]");
        setWithdrawaltDesc(get(result, "withdrawalDescription"));
      })
      .catch((err) => {});
  }

  function check2FAstatus() {
    if (!isEmpty(secretKey)) {
      setIs2FA(true);
    } else {
      setIs2FA(false);
      // message.loading(
      //   "您未设置谷歌验证. 正在跳转谷歌验证设置页...    You have not set up Google 2FA. Redirecting to Google 2FA setup page...",
      //   3
      // );
      message.loading(t("setUp2FA", sourceKey.asset), 3);
      setTimeout(() => {
        navigate(routes.setting.to({ type: "2FA" }));
      }, 3000);
    }
  }

  useEffect(() => {
    getProfileData();
    getServiceCharge();
    getAddressListAPI();
    getDesc();
    check2FAstatus();
  }, []);

  function getProfileData() {
    setLoading(true);
    getProfiles(1, 0, {
      _id: user_login?.profile?._id,
    })
      .then((res) => {
        // console.log(res);
        let data = res?.data;
        data = data[0];
        if (data?.freezeWallet === 1) {
          setDisableWithdraw(true);
        }
        setUpdatedProfile(data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        message.error(err?.message);
        setLoading(false);
      });
  }

  useEffect(() => {
    getUsdtBalanceAPI();
  }, [withdrawFees]);

  function getUsdtBalanceAPI() {
    // let value = {
    //   _id: get(profile, "_id"),
    // };

    calculateWithdrawalLimit({
      profileId: get(profile, "_id"),
    })
      .then((res) => {
        // console.log("limit", res);
        let data = res?.data || {};
        setBalance(parseInt(data?.totalWithdrableAmount || 0));
        setPairingUserInfo(data?.pairingUser);
      })
      .catch((err) => {
        console.log(err);
      });

    // getPairingUser(
    //   1,
    //   0,
    //   {
    //     _id: get(profile, "pairingUserId"),
    //   },
    //   accessKey
    // )
    //   .then((res) => {
    //     let data = get(res, "data");
    //     data = data[0];
    //     if (!isEmpty(data)) {
    //       let totalDeposit = get(data, "totalDeposit") || 0;
    //       let totalWithdrawal = get(data, "totalWithdrawal") || 0;
    //       let totalDirectReward = get(data, "totalDirectReward") || 0;
    //       let totalPairingBonus = get(data, "totalPairingBonus") || 0;
    //       let totalLeaderBonus = get(data, "totalLeaderBonus") || 0;
    //       let totalCompanyPoolBonus = get(data, "totalCompanyPoolBonus") || 0;
    //       let totalCompanyPool1Bonus = get(data, "totalCompanyPool1Bonus") || 0;

    //       let totalWithdrableAmount =
    //         totalDeposit +
    //         totalDirectReward +
    //         totalPairingBonus +
    //         totalLeaderBonus +
    //         totalCompanyPoolBonus +
    //         totalCompanyPool1Bonus;

    //       totalWithdrableAmount = totalWithdrableAmount - totalWithdrawal;
    //       // console.log(totalWithdrableAmount);

    //       getDeposits("all", 0, {
    //         referralCode: get(user_login, "referralCode"),
    //         isAdminTopUp: 1,
    //         amountType: 0,
    //       })
    //         .then((res2) => {
    //           // console.log("deposit res", res2);
    //           let adminTopUpAmount = get(res2, "data");

    //           adminTopUpAmount = sumBy(adminTopUpAmount, "amount");
    //           // console.log("adminTopUpAmount ", adminTopUpAmount);

    //           getWithdrawTransaction(
    //             "all",
    //             0,
    //             {
    //               userId: get(user_login, "referralCode"),
    //               status: 0,
    //             },
    //             accessKey
    //           )
    //             .then((res1) => {
    //               const pendingWithdrawalList = get(res1, "data");

    //               // console.log("balance", balance);
    //               // console.log("onHold_Usdt", onHold_Usdt);
    //               // console.log("totalWithdrableAmount", totalWithdrableAmount);

    //               // let serviceCharge = cloneDeep(withdrawFees);

    //               let totalPendingAmount = sumBy(
    //                 pendingWithdrawalList,
    //                 "amount"
    //               );

    //               pendingWithdrawalList.map((record) => {
    //                 let pendingRecordAmount = get(record, "amount");
    //                 // let pendingRecordServiceCharge = get(
    //                 //   record,
    //                 //   "serviceCharge"
    //                 // );

    //                 totalWithdrableAmount =
    //                   totalWithdrableAmount - pendingRecordAmount;
    //                 return record;
    //               });
    //               totalWithdrableAmount =
    //                 totalWithdrableAmount - adminTopUpAmount;

    //               // console.log("before check wallet", totalWithdrableAmount);

    //               if (totalPendingAmount >= get(data, "wallet")) {
    //                 totalWithdrableAmount = 0;
    //               } else if (totalWithdrableAmount > get(data, "wallet")) {
    //                 totalWithdrableAmount = get(data, "wallet");
    //               }

    //               if (get(data, "wallet") === 0) {
    //                 totalWithdrableAmount = 0;
    //               }

    //               // let amount = form.getFieldValue("amount");

    //               // if(amount > get(data,"wallet")){

    //               // }

    //               // if (onHold_Usdt === 0) {
    //               //   //console.log("hold is 0", withdrawFees);
    //               //   setBalance(balance - serviceCharge);
    //               // } else {
    //               //   setBalance(balance);
    //               // }

    //               setBalance(parseInt(totalWithdrableAmount));
    //               setPairingUserInfo(data);
    //             })
    //             .catch((err) => {
    //               message.error(err?.message);
    //             });
    //         })
    //         .catch((err) => {
    //           message.error(err?.message);
    //         });
    //     }
    //   })
    //   .catch((err) => {
    //     message.error(err?.message);
    //   });
  }

  function getAddressListAPI() {
    let value = {
      _id: get(profile, "_id"),
    };

    getProfiles(1, 0, {
      _id: get(profile, "_id"),
    })
      .then((res) => {
        // console.log(res);
        const profile_data = get(res, "data[0]");
        setAddress(get(profile_data, "addressList") || []);
      })
      .catch((err) => {
        message.error(t("userNotFound", sourceKey.asset));
      });
  }

  let desc_array = [];
  if (!isEmpty(withdrawalDesc)) {
    desc_array = withdrawalDesc.split("\n");
  }
  // const desc_array = withdrawalDesc.split("\n");

  const _renderTips = () => {
    return (
      <div className="mt-5">
        <div className=" text-sm font-semibold">
          {t("tips", sourceKey.asset)}
        </div>
        <br />
        <ul className="list-disc ml-5">
          {desc_array.map((i) => {
            if (!isEmpty(i)) {
              return (
                <>
                  <li>{i}</li>
                  <br />
                </>
              );
            }
          })}
          {/* <li>
            请务必核对提币地址正确性，否则资产将不可找回，最小提现数量$10。
            <br />
            Please make sure the withdrawal address is correct otherwise you
            will not be able to recover your assets; Minimum withdrawal amount:
            $10.
          </li>
          <br />
          <li>
            为保障资金安全，当您账户安全策略变更，密码修改，我们会对提币进行人工审核，请耐心等待工作人员电话或邮件联系。
            <br />
            To ensure the safety of your funds, your withdrawal request will be
            manually reviewed if your security strategy or password is changed.
          </li>
          <br />
          <li>
            请务必确认电脑及浏览器安全，防止信息被篡改或泄漏。
            <br />
            Please make sure that your computer and browser are secured and your
            information protected.
          </li>
          <br />
          <li>
            暂不支持智能合约提币，24小时内完成交易，请您谅解。
            <br />
            Smart Contract withdrawal is not supported for time being, manual
            approval will be done within 24 hours.
          </li>
          <li>
            提币总数不可大于充值加奖励的累计总和
            <br />
            Amout of withdrawal are limited to the total of deposit plus reward.
          </li> */}
        </ul>
      </div>
    );
  };

  function onChangeAmount(e) {
    let amount = e;
    // console.log(e);

    amount = amount - withdrawFees;
    // console.log(amount);

    clearTimeout(timeoutFunc);
    timeoutFunc = setTimeout(() => {
      setWithdrawable(amount);
    }, 500);
  }

  return (
    <React.Fragment>
      <Layout>
        <Spin spinning={loading}>
          <NavHeader
            suffix={
              <React.Fragment>
                <div className="justify-end flex items-center">
                  {t("withdrawal", sourceKey.asset)}
                </div>
              </React.Fragment>
            }
            after={
              <FileProtectOutlined
                className="black  mx-2"
                style={{ fontSize: 23 }}
                onClick={() => navigate(routes.withdrawalList.to())}
              />
            }
            showBack={true}
          >
            <Required2FAWrapper />
            <RequiredPaymentPasswordWrapper />
            
            {disableWithdraw === true && (
              <>
                <div className="px-6 mt-2 ">
                  ***
                  <span className="text-green-400">
                    {" "}
                    {t("freezeWallet", sourceKey.asset)}{" "}
                  </span>
                  ***
                </div>
                <div className="px-6 mb-4">
                  <span>
                    {" "}
                    {t("click", sourceKey.asset)}{" "}
                    <span
                      className="text-blue-400 underline cursor-pointer"
                      onClick={() => {
                        navigate(routes.customerService.to());
                      }}
                    >
                      {t("here", sourceKey.asset)}
                    </span>{" "}
                  </span>
                  <span>{t("forContact", sourceKey.asset)}</span>
                </div>
              </>
            )}
            <Form form={form} style={{ color: "black" }}>
              <div className="px-6 pb-5">
                <div className=" text-sm font-semibold mt-3">USDT -TRC20</div>
                <div className="mt-3">
                  {t("withdrawalAddress", sourceKey.asset)}
                </div>
                <div className="rounded-3xl mt-1">
                  <Form.Item
                    name="address"
                    style={{ margin: 0 }}
                    rules={[
                      {
                        required: true,
                        message: (
                          <React.Fragment>
                            {t("selectAddress", sourceKey.asset)}
                          </React.Fragment>
                        ),
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      disabled={disableWithdraw}
                      style={{ width: "100%" }}
                      placeholder={t("selectAddress", sourceKey.asset)}
                      optionFilterProp="children"
                      autoComplete="new-user"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      suffixIcon={
                        <ToolFilled
                          className="success-color mr-px"
                          onClick={(e) => {
                            navigate(routes.address.to());
                          }}
                          style={{ fontSize: "170%" }}
                        ></ToolFilled>
                      }
                      dropdownRender={(menu) => (
                        <>
                          {isEmpty(address) ? (
                            <div className="flex justify-center items-center">
                              <div>
                                {menu}
                                <div>
                                  <Button
                                    block
                                    className="main-button-color text-xl my-3 "
                                    style={{ borderRadius: 25 }}
                                    onClick={() =>
                                      navigate(routes.address.to())
                                    }
                                  >
                                    {t("add")}
                                  </Button>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <>
                              {menu}
                              <Divider style={{ margin: "8px 0" }} />
                              <Space
                                align="center"
                                //style={{ padding: "0 8px 4px" }}
                              >
                                <Typography.Link
                                  onClick={() => {
                                    navigate(routes.address.to());
                                  }}
                                  className="m-4"
                                >
                                  {t("addWithdrawalAddress", sourceKey.asset)}
                                </Typography.Link>
                              </Space>
                            </>
                          )}
                        </>
                      )}
                    >
                      {map(address, (item, index) => {
                        return (
                          <Select.Option
                            value={get(item, "address") || ""}
                            key={`option-${index}`}
                          >
                            {get(item, "address")}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </div>
                <div className="mt-3">
                  {t("withdrawalAmount", sourceKey.asset)}(USDT):
                </div>
                <div className="rounded-xl mt-1">
                  <Form.Item
                    name="amount"
                    style={{ margin: 0 }}
                    rules={[
                      {
                        required: true,
                        message: (
                          <React.Fragment>
                            {/* 请输入数量 <br />
                            Please insert QTY */}
                            {t("insertQty", sourceKey.asset)}
                          </React.Fragment>
                        ),
                      },
                      {
                        validator: (rule, value, callback) => {
                          if (parseFloat(value) < 10) {
                            callback(
                              <React.Fragment>
                                {/* 最低额度为10 <br />
                                Min amount is 10 */}
                                {t("minTen", sourceKey.asset)}
                              </React.Fragment>
                            );
                          } else if (parseFloat(value) > parseFloat(balance)) {
                            callback(
                              <React.Fragment>
                                {/* 额度需小于余额 <br />
                                Amount should be below than balance */}
                                {t("lessBalance", sourceKey.asset)}
                              </React.Fragment>
                            );
                          }
                          // else if(!isEmpty(value) && !isInteger(parseFloat(value)) || includes(value, '.') ){
                          //   callback(
                          //     <React.Fragment>
                          //       请避免输入小数点 <br />
                          //       Amount should not have decimal point
                          //     </React.Fragment>
                          //   );
                          // }
                          else {
                            callback();
                          }
                        },
                      },
                    ]}
                  >
                    <InputNumber
                      placeholder={t("minTen", sourceKey.asset)}
                      initialValue={0}
                      disabled={disableWithdraw}
                      onChange={onChangeAmount}
                      className="w-full"
                      formatter={(value) =>
                        isNaN(parseInt(value)) ? 0 : parseInt(value)
                      }
                      addonAfter={
                        <span
                          className=" text-yellow-400  cursor-pointer inline-block text-xs"
                          onClick={(e) => {
                            form.setFieldsValue({ amount: balance });
                          }}
                        >
                          {t("all")}
                        </span>
                      }
                    />
                  </Form.Item>
                  {/* <div className="flex mt-2" style={{ fontSize: "0.65rem" }}>
                    **{t("transferCannotWithdraw", sourceKey.asset)}**
                  </div> */}
                  <div className="text-xs flex mt-2">
                    {/* 可用余额 Available :{" "} */}
                    {t("availableBalance", sourceKey.asset)} :{" "}
                    {formatNumber(balance, null, true, 8, true)} **
                    {t("transferCannotWithdraw", sourceKey.asset)}**
                  </div>
                </div>
                <div className="mt-3">
                  {/* 手续费
                  <br />
                  Service Charge: */}
                  {t("serviceCharge", sourceKey.asset)}
                </div>
                <div className="rounded-xl mt-1">
                  <Form.Item
                    initialValue={0}
                    style={{ margin: 0 }}
                    rules={[
                      {
                        required: true,
                        message: t("insertAmount", sourceKey.asset),
                      },
                    ]}
                  >
                    <Input
                      placeholder={t("insertAmount", sourceKey.asset)}
                      className={"input-border"}
                      value={withdrawFees}
                      disabled
                    />
                    <span className="absolute text-yellow-400 inline-block flex right-1 top-2 text-xs">
                      USDT
                    </span>
                  </Form.Item>
                  <div className="flex mt-2">
                    {t("withdrawableAmount", sourceKey.asset)} :{" "}
                    {formatNumber(withdrawable, null, true, 8, true)}
                  </div>
                </div>

                <div className="mt-3">
                  {/* 登入密码
                  <br />
                  Login Password: */}
                  {t("loginPassword", sourceKey.profile)} :
                </div>
                <div className="flex items-center my-3">
                  <span className="inline-block w-full">
                    <Form.Item
                      name="password"
                      rules={[
                        {
                          required: true,
                          message: (
                            <React.Fragment>
                              {/* 请填写登入密码
                              <br /> */}
                              {t("loginPassword", sourceKey.profile)}
                            </React.Fragment>
                          ),
                        },
                      ]}
                    >
                      <Input.Password
                        placeholder={t("loginPassword", sourceKey.profile)}
                        disabled={disableWithdraw}
                        iconRender={(visible) =>
                          visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                        }
                        className={"input-border"}
                      />
                    </Form.Item>
                  </span>
                </div>

                <div className="mt-3">
                  {/* 谷歌验证
                  <br /> */}
                  {/* Google Authentication: */}
                  {t("googleAuth", sourceKey.asset)}
                </div>
                <div className="flex items-center my-3">
                  <span className="inline-block w-full">
                    <Form.Item
                      style={{ margin: 0 }}
                      name="checkCode"
                      initialValue=""
                      rules={[
                        {
                          required: true,
                          message: (
                            <React.Fragment>
                              {t("googleAuthRequired", sourceKey.asset)}
                            </React.Fragment>
                          ),
                        },
                      ]}
                    >
                      {is2FA ? (
                        <Input
                          placeholder="XXXXXX"
                          autoComplete="off"
                          className={"input-border"}
                          disabled={disableWithdraw}
                          // suffix={
                          //     <Button className="main-button-color" shape="round" onClick={(e) => {
                          //         sendEmailVerificationCode();
                          //     }}>Send Code</Button>
                          // }
                        />
                      ) : (
                        <Button
                          onClick={(e) => {
                            navigate(routes.setting.to());
                          }}
                        >
                          {t("setup", sourceKey.asset)}
                        </Button>
                      )}
                    </Form.Item>
                  </span>
                </div>

                {/* <div className='mt-3'>
                  电邮<br />
                  Email:
                </div>
                <div className="rounded-xl mt-1" >
                  <Form.Item
                    name="email"
                    style={{ margin: 0 }}
                    rules={[
                      {
                        required: true,
                        message: 'Please insert your email'
                      }
                    ]}
                  >
                    <Input placeholder="请输入电邮 &#10; Please Enter Email" className={'input-border'} />
                  </Form.Item>
                </div> */}

                {_renderTips()}
                <Button
                  block
                  className="main-button-color border"
                  shape="round"
                  disabled={disableWithdraw}
                  onClick={(e) => {
                    let address = form.getFieldValue("address");
                    let amount = form.getFieldValue("amount");

                    if (amount > get(pairingUserInfo, "wallet")) {
                      message.error(t("amountGreater", sourceKey.asset));
                    } else {
                      setConfirmData({ address, amount });

                      setConfirmModalVisible(true);
                    }
                  }}
                >
                  {/* 提币 Withdrawal */}
                  {t("withdrawal", sourceKey.asset)}
                </Button>
              </div>
            </Form>
            {/* )} */}

            <Modal
              visible={confirmModalVisible}
              onOk={() => {
                handleSubmit();
              }}
              okButtonProps={{ loading: loading }}
              onCancel={() => {
                setConfirmModalVisible(false);
              }}
              className="theme-bg-color"
            >
              {/* <p>
                {" "}
                是否要確認提款 {get(confirmData, "amount")} USDT 进入{" "}
                {get(confirmData, "address")} 的户口? <br />
                扣除服务费后，您的总提款金额为{" "}
                {formatNumber(
                  get(confirmData, "amount") - withdrawFees,
                  null,
                  true,
                  2,
                  true
                )}{" "}
                USDT.
                <br />
              </p> */}

              <p>
                {t("confirmModal1", sourceKey.asset)}{" "}
                {get(confirmData, "amount")}{" "}
                {t("confirmModal2", sourceKey.asset)}{" "}
                {get(confirmData, "address")}? <br />
                {t("confirmModal3", sourceKey.asset)}{" "}
                {formatNumber(
                  get(confirmData, "amount") - withdrawFees,
                  null,
                  true,
                  2,
                  true
                )}{" "}
                USDT.
              </p>
            </Modal>
          </NavHeader>
        </Spin>
      </Layout>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(Withdrawal);
